// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/user+/error-page.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/user+/error-page.tsx");
  import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import { useCallback, useEffect } from "react";
import MainContainer from "~/components/MainContainer";
import { images } from "~/constant/images";
export default function ErrorPage() {
  _s();
  const goToHome = useCallback(() => {
    window.location.href = window.location.origin;
  }, []);
  useEffect(() => {
    setInterval(() => goToHome(), 3000);
  }, [goToHome]);
  return <MainContainer headerImage={images.logo}>
      <div className="flex flex-col justify-center items-center  text-center p-4">
        <div className="bg-white p-10 rounded-lg shadow-md">
          <h1 className="text-9xl font-bold text-red-500 mb-4">
            {" "}
            {"Something went wrong"}
          </h1>

          <p className="text-lg text-gray-600 mb-6">
            {`We're sorry, but something went wrong. You'll be redirected to the home page.`}
          </p>
          <button onClick={goToHome} className="bg-green-500 text-white text-lg py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300">
            {`Return to Home`}
          </button>
        </div>
      </div>
    </MainContainer>;
}
_s(ErrorPage, "YgQD9LoRyT4Ixw7lnLcwaTHbl14=");
_c = ErrorPage;
var _c;
$RefreshReg$(_c, "ErrorPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;